import React from "react";

import "./../sass/style.sass"

const Modal = (props) => {
    const isActive = props.modal === true ? "modal active" : "modal"
    return (
        <div className={isActive}>
            <div className="modal--content">
                <button id="close" onClick={props.close}>✕</button>
                <form>
                    <input name="email" type="email" required id="email" onChange={props.change} placeholder="Ihre E-Mail Adresse"></input>
                    <input name="winner" readOnly type="text" id="winner" value={props.type} style={{ display: 'none' }}></input>
                    <input name="gender" readOnly type="text" id="gender" value={props.gender} style={{ display: 'none' }}></input>
                    <p style={{ fontSize: '12px', color: '#666', display: 'block', margin: '10px 0' }}>Ihre E-Mail Adresse wird weder gespeichert noch weiterverwendet.</p>
                    <input type="submit" value="Senden" onClick={props.send}></input>
                </form>
            </div>
        </div >
    )
}

export default Modal