import React from "react";

const Bullets = (props) => {

    const bullets = props.numOfPages.map((bullet, index) => {
        return <div key={index} id={"b" + (index + 1)} className={index + 1 === props.page - 1 ? "bullet-empty bullet-full" : "bullet-empty"
        }></div >
    });

    return (
        <div className="bullets">
            {bullets}
        </div>
    )


}

export default Bullets