import React from "react"

const Card = (props) => {
    return (
        <li>
            <div
                className="frage"
                onClick={props.click}
                typen={props.typen}>

                <img
                    gender={props.gender}
                    src={props.image}
                    alt={props.image.substr(props.image.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')} />
                {props.subtitle ? <div className="subtitle">{props.subtitle}</div> : null}

            </div>
        </li>
    )
}

export default Card;