import React from "react"
import Modal from "./Modal"
import Button from "./Button"

const Winner = (props) => {

    let results = props.winner.map((brille, i) => {
        return (
            <li key={i}>
                <div className="imageWrapper">
                    <img
                        src={"img/brillen/" + brille.brille}
                        alt={brille.brille.substr(brille.brille.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')}
                    />
                    <div className="divider"></div>
                    <p><strong>{brille.marke}</strong></p>
                </div>
            </li>
        )
    })
    return (
        <section id="ergebnis">
            <div className="q-head">
                <h2>Dein Ergebnis</h2>
                <Button
                    id={props.btnClass}
                    click={props.click}
                    name={props.name}
                />
                <div className="divider"></div>
            </div >
            <div id="ergebnisContent">
                <div className={"resultDescription " + props.face}>
                    <h1>{props.title}</h1>
                    <p>{props.text}</p>
                    <img
                        src={"img/" + props.type + "/" + props.gender + "/keyvisual.jpg"}
                        alt={props.image}
                    />
                </div>
                <div className="resultGlasses">
                    <h3>Diese Brillen passen perfekt zu deinem Typ</h3>
                    <ul className="resultList">
                        {results}
                    </ul>
                </div>
            </div>
            <Modal
                modal={props.modal} close={props.close}
                send={props.send}
                change={props.change}
                winnerEmail={props.winnerEmail}
                type={props.type}
                gender={props.gender}
            />
        </section>
    )
}

export default Winner;