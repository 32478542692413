import React from "react";

const Button = (props) => {

    return (
        <button
            className={props.id}
            onClick={props.click}>{props.name}
        </button>

    )
}

export default Button;