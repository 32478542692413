import React from 'react';
import Card from "./Card";

const Question = (props) => {

    let cardList = props.cards.map((card, index) => {
        return <Card
            image={"img/" + card.bild}
            typen={card.typen}
            key={index}
            gender={card.gender}
            face={card.face}
            data-key={index}
            click={() => props.next(card.typen, card.gender, card.face)}
            subtitle={card.text ? card.text : null}
        />

    })



    return (
        <section
            id={"q" + (props.index - 1)}
            className={"frage" + (props.index - 1)} >
            <div className="q-head">
                <h2>{props.title}</h2>
                <div className="divider"></div>
            </div >
            <ul>
                {cardList}
            </ul>





        </section >
    )


}

export default Question;

