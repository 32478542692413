import React, {
  Component
} from 'react';

import $ from 'jquery';

import './sass/style.sass';

import myState from './Components/state'

import Welcome from './Components/Welcome'
import Question from './Components/Question'
import Bullets from './Components/Bullets'
import Winner from './Components/Winner'

//import axios from 'axios'


class App extends Component {

  state = myState


  createWinnerEmailText = () => {
    const winnerContent = this.state.pages[this.state.index - 1].content[this.state.winner]
    let mailText = '';

    winnerContent[this.state.gender].map(brille => {
      return (
        mailText += `<div class="brille"><img class="bild" src="${window.location.href}img/brillen/${brille.brille}" alt=${brille.brille.substr(brille.brille.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')}/><p class="text">${brille.marke}</p></div>`
      );
    })

    // const emailStyle = `.brille{text-align: center; position: relative; width: 180px; margin-right: 10px; margin-bottom: 10px; display: inline-block}.bild{width: 100%}.text{font-family: sans-serif; text-align: center}.container{width: 100%; max-width: 600px; padding: 20px; background-color: #ffffff;
    // }`

    // const emailContent = `<style>${emailStyle}</style><div class="container"><p style="font-family: sans-serif; color: #333333">Dein Ergebnis vom Online-Test  ist: </p><h1 style="font-family: sans-serif; text-transform: uppercase; color: #686868">${winnerContent.headline}</h1><p style="font-family: sans-serif; color: #333333">${winnerContent.text}</p><div style="width: 100%">${mailText}</div></div>`;

    this.setState({
      winnerEmail: this.state.winner
      // winnerEmail: emailContent
    })

  }


  handleNextClick = (punkte, gender, face) => {

    if (this.state.index < this.state.pages.length) {
      this.setState({
        index: this.state.index + 1,
      })
    }

    if (gender) {
      this.setState({
        gender: gender
      })
    }
    if (face) {
      this.setState({
        face: face,
        winner: face === "small" ? "small" : this.state.winner
      })
    }

    if (punkte !== undefined && punkte.target === undefined) {

      this.setState(prevState => {
        return {
          typen: {
            casual: prevState.typen.casual + punkte.casual,
            business: prevState.typen.business + punkte.business,
            urban: prevState.typen.urban + punkte.urban,
            extravagant: prevState.typen.extravagant + punkte.extravagant,
            glamour: prevState.typen.glamour + punkte.glamour,
            sportlich: prevState.typen.sportlich + punkte.sportlich,
          }
        }
      });
    }


    if (this.state.index === this.state.pages.length - 2) {
      const scores = [
        { typ: "casual", punkte: this.state.typen.casual },
        { typ: "business", punkte: this.state.typen.business },
        { typ: "urban", punkte: this.state.typen.urban },
        { typ: "extravagant", punkte: this.state.typen.extravagant },
        { typ: "glamour", punkte: this.state.typen.glamour },
        { typ: "sportlich", punkte: this.state.typen.sportlich }
      ];
      const winner = scores.reduce((acc, curr) => (curr.punkte > acc.punkte) ? curr : acc)
      this.setState({
        winner: winner.typ

      })
    }

  }

  handleMailButton = () => {

    this.setState({
      isModalOpen: true
    })
    this.createWinnerEmailText();

  }




  handleModalClose = () => {
    this.setState({
      isModalOpen: false
    })

  }

  sendEmail = (e) => {
    e.preventDefault()

    // axios.post('./api/index.js', { email: this.state.email, message: this.state.winnerEmail })
    //   .then(res => {
    //     console.log(res)
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })

    // Send the form with AJAX
    $.ajax({
      data: { email: this.state.email, winner: this.state.winner, gender: this.state.gender },
      type: 'POST',
      url: '/mail.php',
      success: function (data) {
        console.info(data)
        //alert('E-Mail erfolgreich gesendet')
      },
      error: function (xhr, status, err) {
        console.error(status, err.toString())
        //alert('Die E-Mail konnte nicht gesendet werden!')
      }
    })
    this.setState({
      isModalOpen: false
    })

  }

  handleInputChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }


  render() {
    let output;
    switch (this.state.index) {
      case 1:
        output = <Welcome
          overline={this.state.pages[this.state.index - 1].overline}
          title={this.state.pages[this.state.index - 1].title}
          numOfPages={this.state.pages.length}
          next={this.handleNextClick}
          btnClass="btn-start" />
        break;
      case 2: case 3: case 4: case 5: case 6: case 7:
        output = <> <Question
          index={this.state.index}
          cards={this.state.pages[this.state.index - 1].content}
          points={this.state}
          title={this.state.pages[this.state.index - 1].title}
          typen={this.state.pages[this.state.index - 1].typen}
          next={this.handleNextClick} />
          <Bullets
            numOfPages={this.state.pages.slice(1)}
            page={this.state.index} />
        </>
        break;

      case 8:
        output = <> <Question
          index={this.state.index}
          cards={this.state.pages[this.state.index - 1].content}
          title={this.state.pages[this.state.index - 1].title}
          typen={this.state.pages[this.state.index - 1].typen}
          gender={this.state.pages[this.state.index - 1].content.gender}
          next={this.handleNextClick}
          subtitle={this.state.pages[this.state.index - 1].content} />
          <Bullets
            numOfPages={this.state.pages.slice(1)}
            page={this.state.index} />
        </>
        break;
      case 9:
        output = <> <Question
          index={this.state.index}
          cards={this.state.pages[this.state.index - 1].content}
          title={this.state.pages[this.state.index - 1].title}
          next={this.handleNextClick}
          face={this.state.pages[this.state.index - 1].content.face}
          subtitle={this.state.pages[this.state.index - 1].content} />
          <Bullets
            numOfPages={this.state.pages.slice(1)}
            page={this.state.index} />
        </>
        break;
      case 10:
        output = <Winner
          winner={this.state.pages[this.state.index - 1].content[this.state.winner][this.state.gender]}
          title={this.state.pages[this.state.index - 1].content[this.state.winner].headline}
          text={this.state.pages[this.state.index - 1].content[this.state.winner].text}
          gender={this.state.gender}
          type={this.state.winner}
          btnClass="btn"
          modal={this.state.isModalOpen}
          click={this.handleMailButton}
          close={this.handleModalClose}
          send={this.sendEmail}
          change={this.handleInputChange}
          winnerEmail={JSON.stringify(this.state.winnerEmail)}
          name="Ergebnis mailen" />

        break;
      default:
        output = "Default"
        break;
    }
    return (
      <> {output} </>
    );
  }
}

export default App;