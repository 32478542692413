import React from 'react';
import Button from "./Button";

const Welcome = (props) => {

    return (
        <section id="homescreen">
            <div className="hometext">
                <h3>{props.overline}</h3><br />
                <img src="img/start-desktop.png" alt="" />
                <h1>{props.title}</h1>

                <Button
                    id={props.btnClass}
                    click={props.next}
                    name="Starten"
                />

            </div >
        </section>
    )


}

export default Welcome;

