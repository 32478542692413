const myState = {
    index: 1,
    typen: {
        casual: 0,
        business: 0,
        urban: 0,
        extravagant: 0,
        glamour: 0,
        sportlich: 0,
    },
    gender: "",
    face: "",
    winner: "",
    isModalOpen: false,
    email: "",
    winnerEmail: "",

    pages: [{
        title: "Welcher Brillen-typ bist du?",
        overline: "Online-Test"
    },
    {
        title: "Wie würdest du gerne deinen Urlaub verbringen?",
        content: [{
            bild: "aktivurlaub.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 3
            }
        },
        {
            bild: "luxusurlaub.jpg",
            typen: {
                casual: 0,
                business: 1,
                urban: 0,
                extravagant: 0,
                glamour: 3,
                sportlich: 0,
            }
        },
        {
            bild: "entspannungsurlaub.jpg",
            typen: {
                casual: 3,
                business: 1,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "segelurlaub.jpg",
            typen: {
                casual: 0,
                business: 3,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 1,
            }
        },
        {
            bild: "staedtetrip.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 3,
                extravagant: 0,
                glamour: 0,
                sportlich: 0
            }
        },
        {
            bild: "abenteuerurlaub.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 0,
                extravagant: 3,
                glamour: 0,
                sportlich: 1,
            }
        }
        ]
    },
    {
        title: "WELCHE AUTOS WÜRDEST DU GERNE FAHREN?",
        content: [{
            bild: "car_sport.jpg",
            typen: {
                casual: 0,
                business: 3,
                urban: 0,
                extravagant: 0,
                glamour: 1,
                sportlich: 0
            }
        },
        {
            bild: "car_abenteuer.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 3,
            }
        },
        {
            bild: "car_oldtimer.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 0,
                extravagant: 3,
                glamour: 1,
                sportlich: 0,
            }
        },
        {
            bild: "car_familie.jpg",
            typen: {
                casual: 3,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 1,
            }
        },
        {
            bild: "car_elegant.jpg",
            typen: {
                casual: 0,
                business: 1,
                urban: 0,
                extravagant: 0,
                glamour: 3,
                sportlich: 0,
            }
        },
        {
            bild: "car_city.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 3,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        ]
    },
    {
        title: "Welche Zimmer gefallen dir?",
        content: [{
            bild: "room_student.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 3,
                extravagant: 0,
                glamour: 0,
                sportlich: 0
            }
        },
        {
            bild: "room_urban.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 1,
                extravagant: 0,
                glamour: 0,
                sportlich: 3,
            }
        },
        {
            bild: "room_barock.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 0,
                extravagant: 1,
                glamour: 3,
                sportlich: 0,
            }
        },
        {
            bild: "room_gemuetlich.jpg",
            typen: {
                casual: 1,
                business: 3,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "room_kreativ.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 1,
                extravagant: 3,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "room_kinder.jpg",
            typen: {
                casual: 3,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        ]
    },
    {
        title: "Was sind deine Lieblingsgetränke?",
        content: [{
            bild: "drink_whiskey.jpg",
            typen: {
                casual: 0,
                business: 3,
                urban: 0,
                extravagant: 0,
                glamour: 1,
                sportlich: 0
            }
        },
        {
            bild: "drink_champagner.jpg",
            typen: {
                casual: 0,
                business: 1,
                urban: 0,
                extravagant: 0,
                glamour: 3,
                sportlich: 0,
            }
        },
        {
            bild: "drink_cocktail.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 1,
                extravagant: 3,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "drink_wasser.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 3,
            }
        },
        {
            bild: "drink_beer.jpg",
            typen: {
                casual: 3,
                business: 0,
                urban: 1,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "drink_kaffee.jpg",
            typen: {
                casual: 0,
                business: 1,
                urban: 3,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        ]
    },

    {
        title: "Welche Handyhüllen gefallen dir am besten?",
        content: [{
            bild: "handy_verspielt.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 1,
                extravagant: 3,
                glamour: 0,
                sportlich: 0
            }
        },
        {
            bild: "handy_schlicht.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 3,
            }
        },
        {
            bild: "handy_leder.jpg",
            typen: {
                casual: 1,
                business: 3,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "handy_holz.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 3,
                extravagant: 1,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "handy_gold.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 0,
                extravagant: 1,
                glamour: 3,
                sportlich: 0,
            }
        },
        {
            bild: "handy_pur.jpg",
            typen: {
                casual: 3,
                business: 1,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        ]
    },
    {
        title: "Was ist dein Lieblingssport?",
        content: [{
            bild: "sport_surfen.jpg",
            typen: {
                casual: 1,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 3
            }
        },
        {
            bild: "sport_fussball.jpg",
            typen: {
                casual: 3,
                business: 0,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 1,
            }
        },
        {
            bild: "sport_ski.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 0,
                extravagant: 3,
                glamour: 1,
                sportlich: 0,
            }
        },
        {
            bild: "sport_tennis.jpg",
            typen: {
                casual: 1,
                business: 3,
                urban: 0,
                extravagant: 0,
                glamour: 0,
                sportlich: 0,
            }
        },
        {
            bild: "sport_laufen.jpg",
            typen: {
                casual: 0,
                business: 0,
                urban: 3,
                extravagant: 0,
                glamour: 0,
                sportlich: 1,
            }
        },
        {
            bild: "sport_golf.jpg",
            typen: {
                casual: 0,
                business: 1,
                urban: 0,
                extravagant: 0,
                glamour: 3,
                sportlich: 0,
            }
        },
        ]
    },
    {
        title: "Wähle dein Geschlecht",

        content: [{
            bild: 'frau.jpg',
            gender: 'female',
            text: 'Weiblich'
        },
        {
            bild: 'mann.jpg',
            gender: 'male',
            text: 'Männlich'
        },

        ]
    },
    {
        title: "Welche Gesichtsform hast du?",
        isFemale: true,
        content: [{
            bild: 'frau_normal.jpg',
            face: 'normal',
            text: 'Normales Gesicht'
        },
        {
            bild: 'frau_schmal.jpg',
            face: 'small',
            text: 'Schmales Gesicht'
        },
        ]
    },
    {
        title: "Dein Ergebnis",
        content: {
            glamour: {
                headline: 'ZU DIR PASST DER ELEGANTE GLAMOUR-LOOK!',
                text: 'Egal ob schick durch den Alltag oder festlich am Abend – du legst viel Wert auf Stil und Eleganz und bist dabei sehr stilsicher. Zu dir passen besonders glamouröse Brillen, die deinem eleganten Stil optimal schmeicheln.',

                male: [{
                    brille: 'andy_wolfhiltunen_col.jpg',
                    marke: 'Andy Wolf'
                },

                {
                    brille: 'chivenchy0044_2m2.jpg',
                    marke: 'Givenchy'
                },
                {
                    brille: 'cuthbert-chen-cc51t.jpg',
                    marke: 'Cuthbert & Chen'
                },
                {
                    brille: 'diordioressence.jpg',
                    marke: 'Dior'
                },
                {
                    brille: 'pradavpr_17t_black01.jpg',
                    marke: 'Prada'
                }

                ],

                female: [{
                    brille: 'channel3367_c_501.jpg',
                    marke: 'Chanel'
                },
                {
                    brille: 'cuthbert-chen-cc51t.jpg',
                    marke: 'Cuthbert & Chen'
                },
                {
                    brille: 'chivenchy0044_2m2.jpg',
                    marke: 'Givenchy'
                },
                {
                    brille: 'diordioressence.jpg',
                    marke: 'Dior'
                },
                {
                    brille: 'jimmy_choojc_202_in_807.jpg',
                    marke: 'Jimmy Choo'
                },
                {
                    brille: 'pradavpr_53u_2au.jpg',
                    marke: 'Prada'
                }
                ]
            },
            sportlich: {
                headline: 'ZU DIR PASST DER sportliche LOOK!',
                text: 'Hauptsache lässig – Jeans und Sneakers sind für dich kein Fremdwort. Du magst es gerne bequem und unaufgeregt. Zu deiner sportiven Art passen schlichte, funktionale Brillen, die deinen sportlich-unkomplizierten Stil unterstreichen.',

                male: [{
                    brille: 'lindberg-tb150.jpg',
                    marke: 'Lindberg'
                },
                {
                    brille: 'mykita-light_claas.jpg',
                    marke: 'Mykita'
                },
                {
                    brille: 'mykita-lite_ailo01.jpg',
                    marke: 'Mykita'
                },
                {
                    brille: 'randolp-aviator2.jpg',
                    marke: 'Randolph'
                },
                ],


                female: [

                    {
                        brille: 'mykita-light_claas.jpg',
                        marke: 'Mykita'
                    },


                    {
                        brille: 'lindberg-003-c205.jpg',
                        marke: 'Lindberg'
                    },
                ],
            },

            extravagant: {
                headline: 'ZU DIR PASST DER extravagante LOOK!',
                text: 'Du bist ein selbstbewusster Typ und ziehst gerne alle Blicke auf dich. Die Brille dient nicht nur als Sehhilfe, sondern in erster Linie auch als Styling-Accessoire. Zu dir passen besonders ausgefallene Brillenfassungen, mit denen du schnell zum Hingucker wirst.',

                male: [{
                    brille: 'andy_wolf4725_col.jpg',
                    marke: 'Andy Wolf'
                },
                {
                    brille: 'coblens-sauerstoffmaske.jpg',
                    marke: 'Coblens'
                },
                {
                    brille: 'coblens-flugplan.jpg',
                    marke: 'Coblens'
                },


                {
                    brille: 'chrome_heartssinnergasm.jpg',
                    marke: 'Chrome Hearts'
                },


                {
                    brille: 'moncler5016_001.jpg',
                    marke: 'Moncler'
                },
                {
                    brille: 'theopatatas_014.jpg',
                    marke: 'Theo'
                }
                ],

                female: [{
                    brille: 'chrome_heartssinnergasm.jpg',
                    marke: 'Chrome Hearts'
                },
                {
                    brille: 'gucci0285_003.jpg',
                    marke: 'Gucci'
                },

                {
                    brille: 'linda_farrow239.jpg',
                    marke: 'Linda Farrow'
                },
                {
                    brille: 'moncler5016_001.jpg',
                    marke: 'Moncler'
                },
                {
                    brille: 'theopatatas_014.jpg',
                    marke: 'Theo'
                },
                {
                    brille: 'theomille21.jpg',
                    marke: 'Theo'
                },
                ],


            },
            urban: {
                headline: 'ZU DIR PASST DER urbane LOOK!',
                text: 'Stilvoll, modern und angesagt: Du bist modisch auf dem neuesten Stand und weißt was auf den Straßen der Metropolen dieser Welt getragen wird. Dein urbaner Stil ist lässig und funktional zugleich. Zu dir passen Brillenmodelle, die cool wirken, gleichzeitig aber bequem und leger sind. ',

                male: [{
                    brille: 'italia_independent5710v_stn_071.jpg',
                    marke: 'Funk'
                },
                {
                    brille: 'lunor234_36.jpg',
                    marke: 'Lunor'
                },

                ],



                female: [{
                    brille: 'funkasteroid.jpg',
                    marke: 'Funk'
                },
                {
                    brille: 'italia_independent5710v_stn_071.jpg',
                    marke: 'Funk'
                },
                {
                    brille: 'lunor234_36.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'sunday_somewhererocky.jpg',
                    marke: 'Sunday Somewhere'
                }
                ]


            },
            business: {
                headline: 'ZU DIR PASST DER Business LOOK!',
                text: 'Es gibt keine Chance für den zweiten Eindruck und Du möchtest Deinen Stil mit der passenden Brille unterstreichen. Klassische, moderne Brillenfassungen aus hochwertigen Materialien passen besonders gut zu deinem perfekten Business Look. So punktet deine Brille nicht nur im Alltag, sondern wirkt auch als Kompetenzverstärker im Büro.',

                male: [{
                    brille: 'garrett_leight-brooks.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-kinney.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-paloma.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'icberlin-francesca_m.jpg',
                    marke: 'IC! Berlin'
                },
                {
                    brille: 'lunor-a9_mod312.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'lunor-m12_mod2.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'tom_ford-tf5465.jpg',
                    marke: 'Tom Ford'
                },
                {
                    brille: 'tom_ford-tf5489.jpg',
                    marke: 'Tom Ford'
                },

                ],



                female: [

                    {
                        brille: 'garrett_leight-paloma.jpg',
                        marke: 'Garret Leight'
                    },
                    {
                        brille: 'icberlin-kersting.jpg',
                        marke: 'IC! Berlin'
                    },
                    {
                        brille: 'icberlin-francesca_m.jpg',
                        marke: 'IC! Berlin'
                    },

                    {
                        brille: 'lunor-m12_mod2.jpg',
                        marke: 'Lunor'
                    },
                    {
                        brille: 'tom_ford-tf5465.jpg',
                        marke: 'Tom Ford'
                    },
                    {
                        brille: 'tom_ford-tf5489.jpg',
                        marke: 'Tom Ford'
                    },
                    {
                        brille: 'willems-chariestown.jpg',
                        marke: 'Willem’s'
                    },
                    {
                        brille: 'willems-falmouth.jpg',
                        marke: 'Willem’s'
                    }
                ],
            },

            casual: {
                headline: 'ZU DIR PASST DER lockere Casual-LOOK!',
                text: 'Du bist der Casual-Typ und magst es gerne locker. Zwanglose, bequeme Kleidung und die dazu passende Brille unterstreichen deinen Stil. Zu dir passen am besten schlichte Brillenfassungen in unterschiedlichen, aber stets unauffälligen Formen.',


                male: [{
                    brille: 'garrett_leight-brooks.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-kinney.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-paloma.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'hamburg_eyewear-erik.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'peter.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'wolfgang.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'persol-3162-v.jpg',
                    marke: 'Persol'
                },
                {
                    brille: 'reiz-ellipse.jpg',
                    marke: 'Reiz'
                },
                {
                    brille: 'reiz-konkav.jpg',
                    marke: 'Reiz'
                },
                ],

                female: [{
                    brille: 'garrett_leight-brooks.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-kinney.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-paloma.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'hamburg_eyewear-erik.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'almut.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'svante.jpg',
                    marke: 'Hamburg Eyewear'
                },

                {
                    brille: 'persol-3162-v.jpg',
                    marke: 'Persol'
                },
                {
                    brille: 'reiz-ellipse.jpg',
                    marke: 'Reiz'
                },
                {
                    brille: 'reiz-konkav.jpg',
                    marke: 'Reiz'
                }

                ]
            },
            small: {
                headline: 'ZU DIR passen schmale Brillen!',
                text: 'Du bist von Natur aus ein zarter Typ mit einem schmalen Gesicht. Viele Brillen sind zu breit, um deiner Gesichtsform optimal schmeicheln zu können. Zu dir passen daher besonders feine, filigrane Fassungen für zierliche Gesichter.',

                male: [{
                    brille: 'jona102g.jpg',
                    marke: 'Colibri'
                },

                {
                    brille: 'ole-47-103g.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'tony56.jpg',
                    marke: 'Colibri'
                },

                {
                    brille: 'battatura-btt07.jpg',
                    marke: 'Battatura'
                },
                {
                    brille: 'coblens-flugplan.jpg',
                    marke: 'Coblens'
                },

                {
                    brille: 'coblens-ueberholspur.jpg',
                    marke: 'Coblens'
                },

                {
                    brille: 'cuthbert-chen-cc51t.jpg',
                    marke: 'Cuthbert & Chen'
                },
                {
                    brille: 'dior_homme0219_3ma.jpg',
                    marke: 'Dior'
                },
                {
                    brille: 'diordioressence.jpg',
                    marke: 'Dior'
                },
                {
                    brille: 'garrett_leight-kinney.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-brooks.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'garrett_leight-paloma.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'icberlin-francesca_m.jpg',
                    marke: 'IC! Berlin'
                },
                {
                    brille: 'italia_independent5710v_stn_071.jpg',
                    marke: 'Funk'
                },
                {
                    brille: 'jack-francis-fr228.jpg',
                    marke: 'Jack & Francis'
                },

                {
                    brille: 'hamburg_eyewear-erik.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'lunor234_36.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'lunor234_36.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'lunor-a9_mod312.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'mykita-lite_ailo01.jpg',
                    marke: 'Mykita'
                },
                {
                    brille: 'pradavpr_17t_black01.jpg',
                    marke: 'Prada'
                },
                {
                    brille: 'reiz-ellipse.jpg',
                    marke: 'Reiz'
                },
                {
                    brille: 'reiz-konkav.jpg',
                    marke: 'Reiz'
                },
                {
                    brille: 'randolp-aviator2.jpg',
                    marke: 'Randolph'
                },
                {
                    brille: 'theopatatas_014.jpg',
                    marke: 'Theo'
                },
                {
                    brille: 'tom_ford-tf5465.jpg',
                    marke: 'Tom Ford'
                },
                {
                    brille: 'tom_ford-tf5489.jpg',
                    marke: 'Tom Ford'
                },
                ],

                female: [{
                    brille: 'hermine67.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'svantje2.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'stine19.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'stig2grau.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'stella.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'smilla3nude.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'selma.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'mia88.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'karlotta40.jpg',
                    marke: 'Colibri'
                },
                {
                    brille: 'battatura-btt07.jpg',
                    marke: 'Battatura'
                },
                {
                    brille: 'bottega_venetabv013o_009.jpg',
                    marke: 'Bottaga Veneta'
                },
                {
                    brille: 'coblens-flugplan.jpg',
                    marke: 'Coblens'
                },
                {
                    brille: 'coblens-ueberholspur.jpg',
                    marke: 'Coblens'
                },

                {
                    brille: 'cuthbert-chen-cc51t.jpg',
                    marke: 'Cuthbert & Chen'
                },
                {
                    brille: 'diordioressence.jpg',
                    marke: 'Dior'
                },
                {
                    brille: 'garrett_leight-paloma.jpg',
                    marke: 'Garret Leight'
                },
                {
                    brille: 'funkasteroid.jpg',
                    marke: 'Funk'
                },
                {
                    brille: 'gucci0285_003.jpg',
                    marke: 'Gucci'
                },
                {
                    brille: 'icberlin-francesca_m.jpg',
                    marke: 'IC! Berlin'
                },
                {
                    brille: 'icberlin-kersting.jpg',
                    marke: 'IC! Berlin'
                },
                {
                    brille: 'italia_independent5710v_stn_071.jpg',
                    marke: 'Funk'
                },
                {
                    brille: 'channel3367_c_501.jpg',
                    marke: 'Chanel'
                },
                {
                    brille: 'jack-francis-fr228.jpg',
                    marke: 'Jack & Francis'
                },
                {
                    brille: 'hamburg_eyewear-erik.jpg',
                    marke: 'Hamburg Eyewear'
                },
                {
                    brille: 'jimmy_choojc_202_in_807.jpg',
                    marke: 'Jimmy Choo'
                },
                {
                    brille: 'linda_farrow239.jpg',
                    marke: 'Linda Farrow'
                },
                {
                    brille: 'lindberg-003-c205.jpg',
                    marke: 'Lindberg'
                },
                {
                    brille: 'lunor234_36.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'lunor234_36.jpg',
                    marke: 'Lunor'
                },
                {
                    brille: 'mykita-lite_ailo01.jpg',
                    marke: 'Mykita'
                },
                {
                    brille: 'persol-3162-v.jpg',
                    marke: 'Persol'
                },
                {
                    brille: 'pradavpr_53u_2au.jpg',
                    marke: 'Prada'
                },
                {
                    brille: 'reiz-ellipse.jpg',
                    marke: 'Reiz'
                },
                {
                    brille: 'reiz-konkav.jpg',
                    marke: 'Reiz'
                },
                {
                    brille: 'sunday_somewhererocky.jpg',
                    marke: 'Sunday Somewhere'
                },
                {
                    brille: 'theopatatas_014.jpg',
                    marke: 'Theo'
                },
                {
                    brille: 'theomille21.jpg',
                    marke: 'Theo'
                },
                {
                    brille: 'tom_ford-tf5465.jpg',
                    marke: 'Tom Ford'
                },
                {
                    brille: 'tom_ford-tf5489.jpg',
                    marke: 'Tom Ford'
                },
                {
                    brille: 'willems-chariestown.jpg',
                    marke: 'Willem’s'
                },
                {
                    brille: 'willems-falmouth.jpg',
                    marke: 'Willem’s'
                },


                ],

            }
        }
    }


    ],

    isFemale: true,
    isShowing: true

}

export default myState;